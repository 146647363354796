import React, { useEffect, useState } from 'react';
import DefaultTable from '../../../global/table/defaultTable';
import Button from '../../../global/button/button';
import { Collapse } from '../../../global/transitions';
import { Formik } from 'formik';
import RadioGroupOnBlock from '../../../form/choice/radioGroupOnBlock';
import { GLOBAL_ICONS } from '../../../resources/vars';
import Selector from '../../../form/select/selector';
import { SectionType } from '../instrument.types';
import { DefaultOptionType } from '../../../resources/types';
import { MessageAtMenuList, OptionDisabled } from '../../../form/select/components';
import routeBuilder from '../../../service/routeBuilder';
import Input from '../../../form/input/input';
import * as Yup from 'yup';
import useModal from '../../../hooks/useModal';
import { CreationConfirmModal } from '../modals';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getInstrumentOptions, getMe, postInstrument } from '../../../service/api';
import { toast } from 'react-toastify';
import Alert from '../../../global/alert/alert';
import { Section } from '../../common';
import { ACTIVE_INSTRUMENTS, INSTRUMENT_OPTIONS, ME } from '../../../service/queryKeys';
import CheckboxGroupOnBlock from '../../../form/choice/checkboxGroupOnBlock';
import {
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
} from '../../../resources/roles';
import { MoreInfo } from '../../../global/tooltip/common';
import { LinkStyled, RouterStyled } from '../../../global/link/link.styled';
import { SCHOOL_ENV_TYPE } from '../SchoolEnvironmentPage';
import { AnySchema } from 'yup';
import { STUDENTS_SURVEYS_TYPE } from '../StudentSurveysPage';
import { InstrumentType } from './instrumentMonitor';
import { SelectOptionType } from '../../../service/types';
import VideoModal from '../modals/videoModal';
import SideTab from '../../../typeform/SideTab';
import posthog from 'posthog-js';
import VideoAlert from '../../../guides/components/videoAlert';

export const INITIAL_INSTRUMENT_FORM = {
  instance_type: 'surveys',
  school_students_type: '',
  survey_reason: '',
  survey_reason_other: '',
  survey_version: '',
  survey_type: '',
  video_options: [],
  place: '',
  school_classes: [],
  element: undefined,
  dimension: undefined,
};

const INVESTIGATE_SURVEY_REASON = 'Investigate';
const MONITOR_SURVEY_REASON = 'Monitor';
const OTHER_SURVEY_REASON = 'Other';

export const MODEL_SURVEY_TYPE = 'Model for Great Teaching';
export const DIMENSION_SURVEY_TYPE = 'Dimension of Great Teaching';
export const ELEMENT_SURVEY_TYPE = 'Element of Great Teaching';

//Baseline survey versions
export const VERSION_B = 'Version B';
export const VERSION_C = 'Version C';
export const VERSION_D = 'Version D';
export const VERSION_C_D = 'Version C/D';
export const VERSION_E = 'Version E';

const TEACHER_LED_VIDEO_OPTION = 'Teacher-led';
const STUDENT_LED_VIDEO_OPTION = 'Student-led';

//School env survey versions
export const VERSION_ALL_STAFF = 'All staff';
export const VERSION_TEACHING_STAFF = 'Teaching staff';
export const VERSION_NON_TEACHING_STAFF = 'Non-teaching staff';

const getSurveyVersions = (surveyType: string) => {
  return [
    {
      value: VERSION_B,
      label: VERSION_B,
      tooltipMessage:
        'Suggested reading age only; this may also be used with older or younger students. Questions are both text and video.',
      description: 'Suggested reading age: 5-7',
      icon: GLOBAL_ICONS.video,
    },
    {
      value: VERSION_C,
      label: VERSION_C,
      tooltipMessage:
        'Suggested reading age only; this may also be used with older or younger students. Questions are both text and video.',
      description: 'Suggested reading age: 8-14',
      icon: GLOBAL_ICONS.video,
    },
    {
      value: VERSION_D,
      label: VERSION_D,
      tooltipMessage:
        'Suggested reading age only; this may also be used with older or younger students. Questions are text only.',
      description: 'Suggested reading age: 11-16',
      icon: GLOBAL_ICONS.text,
    },
    {
      value: VERSION_E,
      label: VERSION_E,
      tooltipMessage: 'Recommended for students in post-16 settings. Questions are text only.',
      description: 'Recommended for post-16 settings',
      icon: GLOBAL_ICONS.text,
    },
  ].filter((version) => {
    if (version.value === VERSION_B || version.value === VERSION_E) {
      return surveyType === MODEL_SURVEY_TYPE || surveyType === DIMENSION_SURVEY_TYPE;
    }

    return true;
  });
};

const dimensions = [
  { label: 'Dimension 1: Understanding the content', value: 1 },
  {
    label: 'Dimension 2: Creating a supportive environment',
    value: 2,
  },
  {
    label: 'Dimension 3: Maximising opportunity to learn',
    value: 3,
  },
  { label: 'Dimension 4: Activating hard thinking', value: 4 },
];

const InstrumentForm = ({
  id,
  showToggleButton,
  type,
  activeInstruments,
  initialState,
  toggle,
  setToggle,
  isFree,
}: PropType) => {
  const queryClient = useQueryClient();

  const [videoModal, toggleVideoModal] = useModal(
    (props: any) => <VideoModal videoLink={'https://vimeo.com/762253588/c8aac69cfd'} {...props} />,
    {
      title: 'Find out more',
      size: 'lg',
    }
  );

  const SCHEMA = Yup.object().shape({
    type: Yup.string().trim().required(),
    instance_type: Yup.string()
      .nullable()
      .when('type', (type: string, schema: AnySchema) => {
        if (type !== SCHOOL_ENV_TYPE) {
          return Yup.string().trim().required();
        }

        return schema;
      }),
    survey_type: Yup.string()
      .nullable()
      .when('type', (type: string, schema: AnySchema) => {
        if (type !== SCHOOL_ENV_TYPE) {
          return Yup.string().trim().required();
        }

        return schema;
      }),
    survey_reason: Yup.string()
      .nullable()
      .when('type', (type: string, schema: AnySchema) => {
        if (type !== SCHOOL_ENV_TYPE) {
          return Yup.string().trim().required();
        }

        return schema;
      }),
    survey_version: Yup.string().nullable().required(),
    video_options: Yup.array().when('survey_version', (survey_version: string, schema: AnySchema) => {
      if (survey_version === VERSION_C || survey_version === VERSION_B) {
        return Yup.array().min(1).of(Yup.string());
      }

      return schema;
    }),
    place: Yup.string()
      .nullable()
      .when('type', (type: string, schema: AnySchema) => {
        if (type !== SCHOOL_ENV_TYPE) {
          return Yup.string().trim().required();
        }

        return schema;
      }),
    school_classes: Yup.array()
      .nullable()
      .when('type', (type: string, schema: AnySchema) => {
        if (type !== SCHOOL_ENV_TYPE) {
          return Yup.array()
            .min(1)
            .of(
              Yup.object().shape({
                id: Yup.string().trim().required(),
                expected_responses: Yup.number().min(5, 'The expected responses should be at least 5.').required(''),
              })
            );
        }

        return schema;
      }),
    expected_responses: Yup.number()
      .nullable()
      .when('type', (type: string, schema: AnySchema) => {
        if (type === SCHOOL_ENV_TYPE) {
          return Yup.number().min(5, 'The expected responses should be at least 5.').required('');
        }

        return schema;
      }),
    element: Yup.string().nullable().when('survey_type', {
      is: ELEMENT_SURVEY_TYPE,
      then: Yup.string().required(),
    }),
    dimension: Yup.string().nullable().when('survey_type', {
      is: DIMENSION_SURVEY_TYPE,
      then: Yup.string().required(),
    }),
  });

  const { data: me } = useQuery(ME, getMe, {
    staleTime: Infinity,
    select: (data) => data.data.data.me,
  });

  const { data: options } = useQuery(INSTRUMENT_OPTIONS, () => getInstrumentOptions(), {
    select: (data) => data.data.data.options,
    placeholderData: [],
    staleTime: Infinity,
  });

  const mutation = useMutation(postInstrument, {
    onSuccess: () => {
      queryClient.invalidateQueries([ACTIVE_INSTRUMENTS, type], { exact: true });
    },
  });

  const [confirmCreationModal, toggleConfirmCreationModal, setConfirmCreationModalProps] = useModal(
    (props: any) => <CreationConfirmModal {...props} />,
    {
      title: 'Create new student surveys',
    }
  );

  const getOption = (from: 'elements' | 'school_classes' | 'dimensions', value: any) => {
    if (0 === options.length || !options[from] || 0 === options[from].length) {
      if (from === 'school_classes') {
        return [];
      }

      return null;
    }

    if ('elements' === from) {
      return []
        .concat(...options[from].map((dimension: Record<string, any>) => dimension.options))
        .find((option: DefaultOptionType) => option.value === value);
    }

    return options[from].find((option: DefaultOptionType) => option.value === value);
  };

  const getLabels = (values: any) => {
    return {
      ...values,
      element: values.element ? getOption('elements', values.element).label : null,
      school_classes: values.school_classes.map((schoolClass: any) => {
        return {
          label: getOption('school_classes', schoolClass.id).label,
          expected_responses: schoolClass.expected_responses,
        };
      }),
    };
  };

  const isDisabled2 = (values: any) => {
    if ((activeInstruments ?? []).length === 0) {
      return false;
    }

    return activeInstruments!.some((i) => {
      for (const formSchoolClass of values.school_classes ?? []) {
        if (
          i.school_classes.some((schoolClass) => schoolClass.id === formSchoolClass.id) &&
          i.survey_version === values.survey_version
        ) {
          return true;
        }
      }

      return false;
    });
  };

  const isDisabled = (values: any) => {
    if ((activeInstruments ?? []).length === 0) {
      return false;
    }

    return activeInstruments!.some((i) => {
      for (const formSchoolClass of values.school_classes ?? []) {
        if (
          i.school_classes.some((schoolClass) => schoolClass.id === formSchoolClass.id) &&
          i.survey_version === values.survey_version &&
          i.element?.value === values.element &&
          i.dimension?.value === values.dimension
        ) {
          return true;
        }
      }

      return false;
    });
  };

  posthog.setPersonPropertiesForFlags({ id: me?.id });

  const [featureFlag, setFeatureFlag] = useState<any>(undefined);
  const [showAlert, setShowAlert] = useState(type != SCHOOL_ENV_TYPE);

  useEffect(() => {
    posthog.onFeatureFlags(function () {
      if (posthog.getFeatureFlag('test_survey_eng') === 't1') setFeatureFlag('featureFlag1');
      else if (posthog.getFeatureFlag('test_survey_eng') === 't2') setFeatureFlag('featureFlag1');
      else setFeatureFlag(undefined);
    });
  }, []);

  return (
    <>
      {videoModal}
      {options?.development_periods && 0 === (options?.development_periods?.length ?? 0) && (
        <Alert type={'warning'} className={'mb-8'}>
          {me.role === ROLE_TEACHER_FREE ? (
            <p>
              To create a survey you first need to set term dates. You can do this via the &#39;Setup&#39; section in
              the main menu.
            </p>
          ) : me.role === ROLE_TEACHER && !me.school_user_id ? (
            <p>You do not currently have any term dates - please set these up in the Setup page.</p>
          ) : [ROLE_ASSISTANT_GT_COORDINATOR, ROLE_SCHOOL_COORDINATOR].includes(me.role) ? (
            <p>You are not currently in an active term - please amend the dates in School planner.</p>
          ) : (
            <p>
              Your school does not currently have live term dates - please ask your great teaching coordinator to set up
              terms for this academic year.
            </p>
          )}
        </Alert>
      )}
      {showAlert ? (
        <Alert type={'info'} className={'mb-8'}>
          For more guidance on setting up student feedback surveys, including how to do so for multiple different
          classes, click{' '}
          <LinkStyled
            href={
              'https://support.evidencebased.education/en/knowledge/how-do-i-start-using-the-surveys-in-the-great-teaching-toolkit'
            }
            target={'_blank'}
          >
            here
          </LinkStyled>
          .
        </Alert>
      ) : (
        <></>
      )}
      <Formik
        initialValues={initialState}
        validationSchema={SCHEMA}
        validateOnMount={true}
        isInitialValid={type !== SCHOOL_ENV_TYPE && initialState.school_classes.length !== 0}
        onSubmit={(values, { resetForm }) => {
          const data = { ...values };

          if (type === SCHOOL_ENV_TYPE) {
            mutation
              .mutateAsync({
                ...data,
                expected_responses: parseInt(values.expected_responses),
              })
              .then(() => {
                resetForm();
              });
            return;
          }

          // The type is handled through the element/dimension/nothing
          delete data.survey_type;

          if (data.survey_reason === OTHER_SURVEY_REASON) {
            data.survey_reason = values.survey_reason_other;

            delete data.survey_reason_other;
          }

          setConfirmCreationModalProps({
            handleSubmit: () => {
              mutation
                .mutateAsync(data)
                .then(() => {
                  resetForm({
                    values: {
                      ...values,
                      ...INITIAL_INSTRUMENT_FORM,
                    },
                  });

                  toggleConfirmCreationModal(false);
                  toast.success('Your survey link has been created successfully');
                })
                .catch((error) => {
                  toast.error(error?.response?.data?.message ?? 'An unexpected error has occurred');
                });
            },
            values: getLabels(values),
          });
          toggleConfirmCreationModal(true);
        }}
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit, isValid, setValues, errors, touched, handleBlur }) => (
          <Section
            id={id}
            size={null}
            className={'field-mb'}
            headline={
              type === SCHOOL_ENV_TYPE ? (
                'Create your School environment and leadership survey'
              ) : (
                <div className={'flex inline-flex gap-1'}>Create student survey links</div>
              )
            }
            more={
              type === SCHOOL_ENV_TYPE && (
                <>
                  <div>
                    <Button
                      asLink
                      mainColor={'info'}
                      size={'sm'}
                      style={{ padding: 0 }}
                      onClick={() => {
                        toggleVideoModal(true);
                      }}
                    >
                      Find out more
                    </Button>
                  </div>
                </>
              )
            }
          >
            <Collapse when={toggle}>
              <form onSubmit={handleSubmit} className={'section-container w-6/6'}>
                {confirmCreationModal}
                {type !== SCHOOL_ENV_TYPE && (
                  <div className={'mb-8'}>
                    {/************* SideTab user Feedback ****************/}
                    <SideTab formId={'zUiWXuEO'} />

                    <RadioGroupOnBlock
                      required
                      id={'instance_type'}
                      label={'Feedback tool type'}
                      options={[{ value: 'surveys', label: 'Student surveys', icon: GLOBAL_ICONS.surveys }]}
                      handleChange={(value: string) => setFieldValue('instance_type', value)}
                      optionChecked={values.instance_type}
                    />
                  </div>
                )}
                {type !== SCHOOL_ENV_TYPE && (
                  <>
                    <div className={'mb-8'}>
                      <RadioGroupOnBlock
                        required
                        id={'survey_reason'}
                        label={'This survey is to help'}
                        options={[
                          {
                            value: INVESTIGATE_SURVEY_REASON,
                            label: INVESTIGATE_SURVEY_REASON,
                            description: 'an aspect of your teaching practice',
                          },
                          {
                            value: MONITOR_SURVEY_REASON,
                            label: MONITOR_SURVEY_REASON,
                            description: 'an aspect of your teaching practice',
                          },
                          {
                            value: OTHER_SURVEY_REASON,
                            label: OTHER_SURVEY_REASON,
                          },
                        ]}
                        handleChange={(value: string) => {
                          setFieldValue('survey_reason', value);

                          if (value !== OTHER_SURVEY_REASON) {
                            setFieldValue('survey_reason_other', '');
                          }
                        }}
                        optionChecked={values.survey_reason}
                      />
                    </div>
                    {values.survey_reason === OTHER_SURVEY_REASON && (
                      <div className={'mb-4'}>
                        <Input
                          id={'survey_reason_other'}
                          label={'Specify here:'}
                          onBlur={handleBlur}
                          error={
                            touched['survey_reason_other'] && errors.survey_reason_other
                              ? (errors.survey_reason_other as string)
                              : ''
                          }
                          value={values.survey_reason_other ?? ''}
                          onChange={({ target }) => {
                            setFieldValue('survey_reason_other', target.value);
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
                {type !== SCHOOL_ENV_TYPE && (
                  <div className={'mb-4'}>
                    <Selector
                      isMulti
                      required
                      id={'class'}
                      label={'Class'}
                      placeholder={"Please select all the classes you'd like survey links for "}
                      options={options?.school_classes ?? []}
                      isSearchable={false}
                      isClearable={false}
                      value={
                        values.school_classes
                          ? values.school_classes.map((value: any) => getOption('school_classes', value.id))
                          : []
                      }
                      onChange={(options: DefaultOptionType[]) => {
                        setFieldValue(
                          'school_classes',
                          options == null
                            ? []
                            : options.map((option: DefaultOptionType) => {
                                const value = values.school_classes.find(
                                  (schoolClass: any) => schoolClass.id === option.value
                                );

                                if (value) {
                                  return value;
                                }

                                return {
                                  id: option.value,
                                };
                              })
                        );
                      }}
                      customComponents={
                        !isFree
                          ? {
                              MenuList: function MenuList(menuListProps) {
                                return (
                                  <MessageAtMenuList
                                    message={
                                      <>
                                        {"Can't see your class?"} You can create your class(es) by going to the{' '}
                                        <RouterStyled target={'_blank'} to={routeBuilder('classManagement').generate()}>
                                          Classes page
                                        </RouterStyled>
                                        .
                                      </>
                                    }
                                    {...menuListProps}
                                  />
                                );
                              },
                            }
                          : undefined
                      }
                    />
                  </div>
                )}
                {type !== SCHOOL_ENV_TYPE && 0 !== values.school_classes.length && (
                  <div className={'animate-fade-in bg-gray-100 border border-light p-5 mb-8'}>
                    <p className={'mb-3'}>
                      Please enter the maximum number of students you expect to complete this survey:
                    </p>
                    <DefaultTable
                      showCounter={false}
                      id={'expected_responses'}
                      columns={[
                        { id: 'class', label: 'Class' },
                        { id: 'expected', label: 'Expected responses' },
                      ]}
                      rows={values.school_classes.map((schoolClass: any, i: number) => {
                        const label = options.school_classes.find((option: any) => option.value === schoolClass.id)
                          .label;

                        return {
                          id: schoolClass.id,
                          cells: [
                            {
                              id: 'class',
                              content: <label htmlFor={`expected_for--${schoolClass.id}`}>{label}</label>,
                            },
                            {
                              id: 'expected',
                              content: (
                                <Input
                                  id={`expected_for--${schoolClass.id}`}
                                  label={label}
                                  hideLabel
                                  type={'number'}
                                  min={5}
                                  onBlur={handleBlur}
                                  error={
                                    touched[`expected_for--${schoolClass.id}`] &&
                                    errors.school_classes &&
                                    (errors.school_classes as [])[i] &&
                                    ((errors.school_classes as [])[i] as any)?.expected_responses
                                      ? ((errors.school_classes as [])[i] as any)?.expected_responses
                                      : ''
                                  }
                                  placeholder={'Please enter a number'}
                                  value={schoolClass.expected_responses ?? ''}
                                  onChange={({ target }) => {
                                    setFieldValue(
                                      'school_classes',
                                      values.school_classes
                                        ? values.school_classes.reduce((acc: any, value: any) => {
                                            if (value.id === schoolClass.id) {
                                              acc.push({
                                                ...schoolClass,
                                                expected_responses: '' !== target.value ? parseInt(target.value) : '',
                                              });
                                              return acc;
                                            }

                                            acc.push(value);
                                            return acc;
                                          }, [])
                                        : []
                                    );
                                  }}
                                />
                              ),
                            },
                          ],
                        };
                      })}
                    />
                  </div>
                )}
                {type !== SCHOOL_ENV_TYPE && (
                  <div className={'mb-8'}>
                    <RadioGroupOnBlock
                      required
                      id={'place'}
                      label={'Where will students complete this survey?'}
                      options={[
                        { value: 'class', label: 'In class' },
                        { value: 'home', label: 'At home' },
                      ]}
                      handleChange={(value: string) => setFieldValue('place', value)}
                      optionChecked={values.place}
                    />
                  </div>
                )}
                {type !== SCHOOL_ENV_TYPE && (
                  <>
                    <div className={'mb-8'}>
                      <RadioGroupOnBlock
                        required
                        id={'survey_type'}
                        label={'Survey type'}
                        options={[
                          {
                            value: MODEL_SURVEY_TYPE,
                            label: MODEL_SURVEY_TYPE,
                            description: 'All four dimensions',
                          },
                          {
                            value: DIMENSION_SURVEY_TYPE,
                            label: DIMENSION_SURVEY_TYPE,
                            description: 'One dimension',
                          },
                          {
                            value: ELEMENT_SURVEY_TYPE,
                            label: ELEMENT_SURVEY_TYPE,
                          },
                        ]}
                        handleChange={(value: string) => {
                          setValues({
                            ...values,
                            survey_type: value,
                            survey_version: value !== values.survey_type ? '' : values.survey_version,
                            video_options: value !== values.survey_type ? [] : values.video_options,
                            element: value !== values.survey_type ? undefined : values.element,
                            dimension: value !== values.survey_type ? undefined : values.dimension,
                          });
                        }}
                        optionChecked={values.survey_type}
                      />
                    </div>
                    {values.survey_type && (
                      <div className={'mb-8'}>
                        <RadioGroupOnBlock
                          required
                          id={'survey_version'}
                          label={'Survey version'}
                          options={getSurveyVersions(values.survey_type)}
                          handleChange={(value: string) => {
                            setValues({
                              ...values,
                              survey_version: value,
                              dimension: values.dimension === 1 && value === VERSION_B ? null : values.dimension,
                              video_options: value !== values.survey_version ? [] : values.video_options,
                            });
                          }}
                          optionChecked={values.survey_version}
                        />
                      </div>
                    )}
                    {(values.survey_version === VERSION_B || values.survey_version === VERSION_C) && (
                      <div className={'mb-8'}>
                        <CheckboxGroupOnBlock
                          required
                          id={'video_options'}
                          label={'Video option'}
                          options={[
                            {
                              value: TEACHER_LED_VIDEO_OPTION,
                              label: TEACHER_LED_VIDEO_OPTION,
                              tooltipMessage: 'Play the video questions to the whole class or a group of students',
                              icon: GLOBAL_ICONS.teacher,
                            },
                            {
                              value: STUDENT_LED_VIDEO_OPTION,
                              label: STUDENT_LED_VIDEO_OPTION,
                              description: 'Headphones required',
                              tooltipMessage: 'Students play each video question in their own time',
                              icon: GLOBAL_ICONS.student,
                            },
                          ]}
                          handleChange={(value: string) => {
                            if (values.video_options.includes(value)) {
                              setFieldValue(
                                'video_options',
                                values.video_options.filter((option: string) => option !== value)
                              );
                            } else {
                              setFieldValue('video_options', [...values.video_options, value]);
                            }
                          }}
                          optionsChecked={values.video_options}
                        />
                      </div>
                    )}
                    {values.survey_type === ELEMENT_SURVEY_TYPE && (
                      <div className={'mb-8'}>
                        <Selector
                          required
                          id={'element'}
                          label={'Element'}
                          placeholder={'Please select an element'}
                          options={options?.elements ?? []}
                          isOptionDisabled={(option: DefaultOptionType) =>
                            activeInstruments?.find(
                              (instance: Record<any, any>) => instance.element?.value === option.value
                            ) && isDisabled2(values)
                          }
                          isSearchable={false}
                          isClearable={false}
                          value={getOption('elements', values.element) || null}
                          onChange={(option: DefaultOptionType) => setFieldValue('element', option.value)}
                          customComponents={{
                            Option: function Option(optionProps) {
                              return <OptionDisabled {...optionProps} />;
                            },
                          }}
                        />
                      </div>
                    )}
                    {values.survey_type === DIMENSION_SURVEY_TYPE && (
                      <div className={'mb-8'}>
                        <Selector
                          required
                          id={'dimensions'}
                          label={'Dimension'}
                          placeholder={'Please select a dimension'}
                          options={dimensions.filter(
                            (option: SelectOptionType) => !(values.survey_version === VERSION_B && option.value === 1)
                          )}
                          isSearchable={false}
                          isClearable={false}
                          value={getOption('dimensions', values.dimension) || null}
                          onChange={(option: DefaultOptionType) => setFieldValue('dimension', option.value)}
                          customComponents={{
                            Option: function Option(optionProps) {
                              return <OptionDisabled {...optionProps} />;
                            },
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
                {SCHOOL_ENV_TYPE === type && (
                  <div className={'mb-8'}>
                    <RadioGroupOnBlock
                      required
                      id={'survey_version'}
                      label={'Survey version'}
                      options={[
                        {
                          value: VERSION_ALL_STAFF,
                          label: VERSION_ALL_STAFF,
                          icon: GLOBAL_ICONS.text,
                        },
                        // {
                        //   value: VERSION_TEACHING_STAFF,
                        //   label: VERSION_TEACHING_STAFF,
                        //   icon: GLOBAL_ICONS.text,
                        // },
                        // {
                        //   value: VERSION_NON_TEACHING_STAFF,
                        //   label: VERSION_NON_TEACHING_STAFF,
                        //   icon: GLOBAL_ICONS.text,
                        // },
                      ]}
                      handleChange={(value: string) => {
                        setValues({
                          ...values,
                          survey_version: value,
                          video_options: value !== values.survey_version ? [] : values.video_options,
                        });
                      }}
                      optionChecked={values.survey_version}
                    />
                  </div>
                )}
                {SCHOOL_ENV_TYPE === type && values.survey_version && (
                  <div className={'animate-fade-in bg-gray-100 border border-light p-5 mb-8'}>
                    <p className={'mb-3'}>
                      Please enter the maximum number of people you expect to complete this survey:
                    </p>
                    <DefaultTable
                      showCounter={false}
                      id={'expected_responses'}
                      columns={[{ id: 'expected', label: 'Expected responses' }]}
                      rows={[
                        {
                          id: 'expected_for_school',
                          cells: [
                            {
                              id: 'expected',
                              content: (
                                <Input
                                  id={'expected_for_school'}
                                  type={'number'}
                                  min={0}
                                  onBlur={handleBlur}
                                  error={
                                    touched['expected_for_school'] && errors.expected_responses
                                      ? (errors.expected_responses as string)
                                      : ''
                                  }
                                  placeholder={'Please enter a number'}
                                  value={values.expected_responses ?? ''}
                                  onChange={({ target }) => {
                                    setFieldValue('expected_responses', target.value);
                                  }}
                                />
                              ),
                            },
                          ],
                        },
                      ]}
                    />
                  </div>
                )}
                <Button
                  type={'submit'}
                  className={'mt-5'}
                  disabled={!isValid || mutation.isLoading || isDisabled(values)}
                >
                  {!mutation.isLoading ? 'Create' : 'Loading...'}
                </Button>
              </form>
            </Collapse>
          </Section>
        )}
      </Formik>
    </>
  );
};

type PropType = {
  id: SectionType;
  showToggleButton: boolean;
  type?: typeof STUDENTS_SURVEYS_TYPE | typeof SCHOOL_ENV_TYPE;
  activeInstruments?: InstrumentType[];
  initialState: any;
  toggle: boolean;
  setToggle: (toggle: boolean) => void;
  isFree?: boolean;
};

export default InstrumentForm;
