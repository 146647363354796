import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import routeBuilder from '@app/component/src/service/routeBuilder';
import '@app/asset/styles/app.scss';
import { isLoggedIn } from '@app/component/src/service/auth';
import { useQuery } from 'react-query';
import { getMe } from '@app/component/src/service/api';
import {
  isStcp,
  niotRole,
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_MAT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_SYS_ADMIN,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
  TAG_STCP_ADMIN,
  TAG_STCP_COORDINATOR,
  TAG_STCP_TEACHER,
} from '@app/component/src/resources/roles';
import {
  ClassManagementPage,
  ConfirmationPage,
  HomePage,
  LoginPage,
  LogoutPage,
  PathwaysLogPage,
  RegistrationPage,
  ResetPasswordPage,
  SurveyPage,
  UserManagementPage,
  SchoolManagement,
  EditUserPage,
  GroupManagementPage,
  EditSchoolPage,
  VideoObservationPage,
  VideoPlayerPage,
  StudentSurveysPage,
  PathwaysV2Page,
  PathwaysPage,
  CPDPassportPage,
  WebinarManagementPage,
  GreatTeachingInsetPage,
  CommunityDashboard,
  ResourcePage,
  ResourceDashboard,
  ModelForGreatTeachingPage,
  PathwaysV3Page,
  DevelopmentActivities,
} from '@app/component/src/pages';
import ConditionalRoute from '@app/component/src/service/conditionalRoute';
import EarlyAccessPage from '@app/component/src/service/EarlyAccessPage';
import GlobalNotificationsManagementPage from '@app/component/src/pages/globalNotifications/globalNotificationsManagementPage';
import OrganisationManagementPage from '@app/component/src/pages/organisation/organisationManagementPage';
import { ME } from '@app/component/src/service/queryKeys';
import { hotjar } from 'react-hotjar';
import { useLocation } from 'react-router-dom';
import SchoolFeedbackPage from '@app/component/src/pages/feedback/SchoolFeedbackPage';
import FeedbackPage from '@app/component/src/pages/feedback/FeedbackPage';
import SchoolEnvironmentPage from '@app/component/src/pages/instrument/SchoolEnvironmentPage';
import GreatTeachingTeamsPage from '@app/component/src/pages/greatTeachingTeams/GreatTeachingTeamsPage';
import GreatTeachingTeamsWorkspacePage from '@app/component/src/pages/greatTeachingTeamsWorkspace/GreatTeachingTeamsWorkspacePage';
import CompleteRegistrationPage from '@app/component/src/pages/auth/CompleteRegistrationPage';
import EseRegistrationEnglishSite from '@app/component/src/pages/auth/eseRegistrationEnglishSite';
import EseRegistrationArabicSite from '@app/component/src/pages/auth/eseRegistrationArabicSite';
import EseCompleteRegistration from '@app/component/src/pages/auth/eseCompleteRegistration';
import PathwaysV3WorkspacePage from '@app/component/src/pages/pathway/pathwaysV3WorkspacePage';
import posthog from 'posthog-js';
import { MonitoringPage } from '@app/component/src/pages/monitoring/MonitoringPage';

function AppRoutes() {
  const windowTyped: any = window;
  const { pathname } = useLocation();

  const extractParamsTest = (link: string = window.location.href) => {
    const startIndex = link.indexOf('.com/') + '.com/'.length;

    const extractedString = link.slice(startIndex);
    const decodedString = extractedString.replace(/%26/g, '&');
    const urlParams = new URLSearchParams(decodedString);

    let directTo = urlParams.get('directTo');
    let keywordSearch = urlParams.get('keywordSearch');
    let resourcePageFilters = urlParams.get('resourcePageFilters');

    if (directTo) {
      if (directTo.includes(';')) {
        directTo = directTo.split(';')[0];
      }
      localStorage.removeItem('directTo');
      localStorage.setItem('directTo', directTo);
    }

    if (keywordSearch) {
      if (keywordSearch.includes(';')) {
        keywordSearch = keywordSearch.split(';')[0];
      }
      localStorage.removeItem('keywordSearch');
      localStorage.setItem('keywordSearch', keywordSearch);
    }

    if (resourcePageFilters) {
      if (resourcePageFilters.includes(';')) {
        resourcePageFilters = resourcePageFilters.split(';')[0];
      }
      localStorage.removeItem('resourcePageFilters');
      localStorage.setItem('resourcePageFilters', resourcePageFilters);
    }

    return;
  };

  extractParamsTest();

  const checkRedirect = () => {
    const directTo = localStorage.getItem('directTo');
    localStorage.removeItem('directTo');
    if (directTo) {
      const link = `https://preview.toolkit.greatteaching.com/${directTo}`;
      window.location.href = link;
    }
  };

  const { data: user, isSuccess } = useQuery([ME], () => getMe(), {
    staleTime: Infinity,
    select: (data) => data.data,
    enabled: isLoggedIn(),
  });

  const userRole = user?.data.me.role!;

  const checkEmulateStarter = (role: string) => {
    const emulateStarter = localStorage.getItem('emulateStarter');
    if (role === 'ROLE_TEACHER_FREE' && !emulateStarter) localStorage.setItem('emulateStarter', '4.3');
    else if (role != 'ROLE_TEACHER_FREE') localStorage.removeItem('emulateStarter');
  };

  useEffect(() => {
    posthog.capture('$pageview', { '$current_url': pathname })
    document.getElementById('root')!.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    hotjar.initialize(parseInt(process.env.REACT_APP_HOTJAR_ID ?? '0'), 6);

    windowTyped.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_SITE,
      publishableKey: process.env.REACT_APP_PUBLISHABLE_KEY,
    });
  }, []);

  if (!isLoggedIn()) {
    return (
      <Routes>
        <Route path={routeBuilder('login').route} element={<LoginPage />} />
        <Route path={routeBuilder('register').route} element={<RegistrationPage />} />
        <Route path={routeBuilder('registerConfirmation').route} element={<ConfirmationPage />} />
        <Route path={routeBuilder('resetPassword').route} element={<ResetPasswordPage />} />
        <Route path={routeBuilder('completeRegistration').route} element={<CompleteRegistrationPage />} />
        <Route path={routeBuilder('eseRegistrationEnglishSite').route} element={<EseRegistrationEnglishSite />} />
        <Route path={routeBuilder('eseRegistrationArabicSite').route} element={<EseRegistrationArabicSite />} />
        <Route path={routeBuilder('eseCompleteRegistration').route} element={<EseCompleteRegistration />} />
        <Route path={routeBuilder('survey').route} element={<SurveyPage />} />
        <Route path="*" element={<Navigate replace to={routeBuilder('login').generate()} />} />
      </Routes>
    );
  }

  if (isSuccess) {
    checkEmulateStarter(user.data.me.role);
    checkRedirect();

    posthog.init('phc_uDMWcuTSh6emGFgLw46rvA1ZLAk9lYbS7FcXEQVUMR5', {
      api_host: 'https://eu.posthog.com',
      opt_in_site_apps: true,
      session_recording: {
        inlineStylesheet: false,
      },
      enable_recording_console_log: true,
      // ... other options
    });

    const userTag = niotRole(user.data.me);

    const stcpTag = isStcp(user.data.me);

    const isNiotAndStcpAdmin = userTag === 'NIOT' && stcpTag === TAG_STCP_ADMIN;

    posthog.identify(user.data.me.id, {
      firstName: user.data.me.first_name,
      lastName: user.data.me.last_name,
      email: user.data.me.email,
      role: 'null' !== userTag ? userTag : user.data.me.role,
      organisation: user.data.me.organisation,
    });

    const isFree = userRole === ROLE_TEACHER_FREE;

    return (
      <Routes>
        <Route path={routeBuilder('logout').route} element={<LogoutPage />} />
        <Route path={routeBuilder('register').route} element={<RegistrationPage me={user.data.me} />} />
        <Route path={routeBuilder('survey').route} element={<SurveyPage />} />
        <Route
          path={routeBuilder('userDetails').route}
          element={
            <ConditionalRoute condition={!stcpTag}>
              <EditUserPage me={user.data.me} />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('schoolDetails').route}
          element={
            <ConditionalRoute condition={[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole)}>
              <EditSchoolPage />
            </ConditionalRoute>
          }
        />
        <Route path={routeBuilder('home').route} element={<HomePage me={user.data.me} />} />
        <Route
          path={routeBuilder('userManagement').route}
          element={
            <ConditionalRoute
              condition={
                [
                  ROLE_SYS_ADMIN,
                  ROLE_MAT_COORDINATOR,
                  ROLE_SCHOOL_COORDINATOR,
                  ROLE_ASSISTANT_GT_COORDINATOR,
                  ROLE_TEACHER_FREE,
                ].includes(userRole) || stcpTag === TAG_STCP_COORDINATOR
              }
            >
              {user.data.me.is_induction_lead ? (
                <MonitoringPage me={user.data.me} />
              ) : (
                <UserManagementPage me={user.data.me} isFree={isFree} />
              )}
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('monitoring').route}
          element={
            <ConditionalRoute
              condition={
                user.data.me.is_associate_college ||
                user.data.me.is_induction_lead ||
                user.data.me.is_regional_lead ||
                user.data.me.is_niot ||
                userRole === ROLE_SYS_ADMIN ||
                stcpTag === TAG_STCP_ADMIN
              }
            >
              <MonitoringPage me={user.data.me} />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('schoolManagement').route}
          element={
            <ConditionalRoute condition={[ROLE_SYS_ADMIN].includes(userRole)}>
              <SchoolManagement role={userRole as typeof ROLE_SYS_ADMIN} />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('resourceDashboard').route}
          element={
            <ConditionalRoute condition={[ROLE_SYS_ADMIN].includes(userRole)}>
              <ResourceDashboard />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('groupManagement').route}
          element={
            <ConditionalRoute
              condition={
                ([ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) ||
                  (userRole === ROLE_TEACHER && user?.data.me.teacher_bulk_subscription != null)) &&
                !stcpTag
              }
            >
              <GroupManagementPage me={user.data.me} />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('notificationManagement').route}
          element={
            <ConditionalRoute condition={[ROLE_SYS_ADMIN].includes(userRole)}>
              <GlobalNotificationsManagementPage />
            </ConditionalRoute>
          }
        />
        {/*<Route*/}
        {/*  path={routeBuilder('eventsManagement').route}*/}
        {/*  element={*/}
        {/*    <ConditionalRoute*/}
        {/*      condition={*/}
        {/*        ([*/}
        {/*          ROLE_SYS_ADMIN,*/}
        {/*          ROLE_MAT_COORDINATOR,*/}
        {/*          ROLE_SCHOOL_COORDINATOR,*/}
        {/*          ROLE_ASSISTANT_GT_COORDINATOR,*/}
        {/*        ].includes(userRole) ||*/}
        {/*          user?.data.me.is_niot ||*/}
        {/*          user?.data.me.is_regional_lead ||*/}
        {/*          user?.data.me.is_associate_college) &&*/}
        {/*        !stcpTag*/}
        {/*      }*/}
        {/*    >*/}
        {/*      <EventsPage me={user.data.me} />*/}
        {/*    </ConditionalRoute>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path={routeBuilder('classManagement').route}
          element={
            <ConditionalRoute
              condition={
                ([ROLE_TEACHER, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) &&
                  !stcpTag) ||
                isNiotAndStcpAdmin
              }
            >
              <ClassManagementPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('studentSurveys').route}
          element={
            <ConditionalRoute
              condition={
                ([ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                  userRole
                ) &&
                  !stcpTag) ||
                isNiotAndStcpAdmin
              }
            >
              <StudentSurveysPage isFree={isFree} />
            </ConditionalRoute>
          }
        />
        <Route path={routeBuilder('pathways').route}>
          <Route
            path={':id/v1'}
            element={
              <ConditionalRoute
                condition={
                  [ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                    userRole
                  ) &&
                  !['MENTOR', 'ECT'].includes(userTag) &&
                  !stcpTag
                }
              >
                <PathwaysPage />
              </ConditionalRoute>
            }
          />
          <Route
            path={':id/v2'}
            element={
              <ConditionalRoute
                condition={
                  [ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                    userRole
                  ) && !stcpTag
                }
              >
                <PathwaysV2Page role={userRole} />
              </ConditionalRoute>
            }
          />
          <Route
            path={':id'}
            element={
              <ConditionalRoute
                condition={
                  [ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                    userRole
                  ) &&
                  !['MENTOR', 'ECT'].includes(userTag) &&
                  !stcpTag
                }
              >
                <PathwaysV3WorkspacePage />
              </ConditionalRoute>
            }
          />
          <Route
            path={''}
            element={
              <ConditionalRoute
                condition={
                  [ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                    userRole
                  ) &&
                  !['MENTOR', 'ECT'].includes(userTag) &&
                  !stcpTag
                }
              >
                <PathwaysV3Page role={userRole} />
              </ConditionalRoute>
            }
          />
        </Route>
        <Route
          path={routeBuilder('organisationManagement').route}
          element={
            <ConditionalRoute
              condition={
                ([ROLE_SCHOOL_COORDINATOR, ROLE_SYS_ADMIN, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) ||
                  (userRole === ROLE_TEACHER && user.data.me.teacher_bulk_subscription == null)) &&
                !stcpTag
              }
            >
              <OrganisationManagementPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('pathwaysHistory').route}
          element={
            <ConditionalRoute
              condition={
                [ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                  userRole
                ) && !stcpTag
              }
            >
              <PathwaysLogPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('userFeedback').route}
          element={
            <ConditionalRoute
              condition={
                ([
                  ROLE_TEACHER,
                  ROLE_TEACHER_FREE,
                  ROLE_MAT_COORDINATOR,
                  ROLE_SCHOOL_COORDINATOR,
                  ROLE_ASSISTANT_GT_COORDINATOR,
                ].includes(userRole) &&
                  !stcpTag) ||
                isNiotAndStcpAdmin
              }
            >
              <FeedbackPage userRole={userRole} />
            </ConditionalRoute>
          }
        />

        <Route
          path={routeBuilder('schoolFeedback').route}
          element={
            <ConditionalRoute
              condition={[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) && !stcpTag}
            >
              <SchoolFeedbackPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('videoObservation').route}
          element={
            <ConditionalRoute
              condition={
                ([ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                  userRole
                ) &&
                  !stcpTag) ||
                isNiotAndStcpAdmin
              }
            >
              <VideoObservationPage userId={user.data.me.id} isFree={isFree} />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('videoPlayer').route}
          element={
            <ConditionalRoute
              condition={
                [ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                  userRole
                ) && !stcpTag
              }
            >
              <VideoPlayerPage userId={user.data.me.id} isFree={isFree} />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('schoolEnv').route}
          element={
            <ConditionalRoute
              condition={[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) && !stcpTag}
            >
              <SchoolEnvironmentPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('cpdPassport').route}
          element={
            <ConditionalRoute
              condition={
                ([ROLE_TEACHER, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR, ROLE_TEACHER_FREE].includes(
                  userRole
                ) &&
                  !stcpTag) ||
                isNiotAndStcpAdmin
              }
            >
              <CPDPassportPage userRole={userRole} />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('webinarManagementPage').route}
          element={
            <ConditionalRoute condition={[ROLE_SYS_ADMIN].includes(userRole)}>
              <WebinarManagementPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('greatTeachingInset').route}
          element={
            <ConditionalRoute condition={[ROLE_TEACHER_FREE].includes(userRole)}>
              <GreatTeachingInsetPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('communityDashboard').route}
          element={
            <ConditionalRoute condition={[ROLE_SYS_ADMIN].includes(userRole)}>
              <CommunityDashboard />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('greatTeachingTeamsWorkspace').route}
          element={
            <ConditionalRoute
              condition={
                ([ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                  userRole
                ) &&
                  !user!.data.me.mentor &&
                  !user!.data.me.is_early_career_teacher &&
                  !user!.data.me.is_induction_lead &&
                  !stcpTag) ||
                isNiotAndStcpAdmin
              }
            >
              <GreatTeachingTeamsWorkspacePage />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('greatTeachingTeams').route}
          element={
            <ConditionalRoute
              condition={
                ([ROLE_TEACHER, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) &&
                  !user!.data.me.mentor &&
                  !user!.data.me.is_early_career_teacher &&
                  !user!.data.me.is_induction_lead &&
                  !stcpTag) ||
                isNiotAndStcpAdmin ||
                isFree
              }
            >
              <GreatTeachingTeamsPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('mentoringTeamsWorkspace').route}
          element={
            <ConditionalRoute
              condition={
                user.data.me.mentor ||
                (ROLE_TEACHER === userRole && null != user.data.me.mentee_group_id && !stcpTag) ||
                isFree
              }
            >
              <GreatTeachingTeamsWorkspacePage />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('mentoringTeams').route}
          element={
            <ConditionalRoute
              condition={
                (user.data.me.mentor ||
                  (ROLE_TEACHER === userRole && true === user.data.me.is_member_of_any_mentee_group)) &&
                !stcpTag
              }
            >
              <GreatTeachingTeamsPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('modelForGreatTeaching').route}
          element={
            <ConditionalRoute condition={!stcpTag || isNiotAndStcpAdmin}>
              <ModelForGreatTeachingPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('resourcePage').route}
          element={
            <ConditionalRoute
              condition={
                ([ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                  userRole
                ) &&
                  (!stcpTag || stcpTag === TAG_STCP_TEACHER)) ||
                isNiotAndStcpAdmin
              }
            >
              <ResourcePage isFree={isFree} />
            </ConditionalRoute>
          }
        />
        <Route
          path={routeBuilder('developmentActivities').route}
          element={
            <ConditionalRoute
              condition={
                [ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                  userRole
                ) && !stcpTag
              }
            >
              <DevelopmentActivities />
            </ConditionalRoute>
          }
        />

        {Object.entries(user.meta?.early_access_items ?? []).map((item) => (
          <Route path={item[1]['path']} key={item[0]} element={<EarlyAccessPage item={item[0]} />} />
        ))}
        <Route path="*" element={<Navigate replace to={routeBuilder('home').route} />} />
      </Routes>
    );
  }

  return <></>;
}

export default AppRoutes;
