import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { SCHOOL_METRICS } from '../../../service/queryKeys';
import MetricBox from './metricBox';
import Progress from '../../../global/progress/progress';
import Selector from '../../../form/select/selector';
import Button from '../../../global/button/button';
import { getSchoolAggregatedMetrics } from '../../../service/api/schoolApi';
import { Document, Page, pdf, Text, View } from '@react-pdf/renderer';
import PDFLayout, { PDF_STYLES } from '../../../layout/pdf';
import { saveAs } from 'file-saver';
import PdfTextMetrics from '../pdfComponents/pdfTextMetrics';
import PdfViewMetrics from '../pdfComponents/pdfViewMetrics';
import ElementSquare from '../../mfgtPage/components/elementSquare';
import { starterTemplateDevelopmentActivities } from '../../../resources/starterTemplateData';

const AggregatedMetrics = ({
  academicYearsOptions,
  isFree,
  me,
}: {
  academicYearsOptions: any;
  isFree: boolean;
  me?: any;
}) => {

  const [academicYears, setAcademicYears] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const schoolMetrics = isFree
    ? starterTemplateDevelopmentActivities
    : useQuery(
        [SCHOOL_METRICS, academicYears],
        () => getSchoolAggregatedMetrics({ academic_year_ids: academicYears.map((year: any) => year.value) }),
        {
          select: (data) => data.data.data,
        }
      );

  const dataRetriever = (data: any) => {
    return {
      school: data?.school ?? '',
      introduction: Math.round(data?.foundation_course?.onboarding ?? 0),
      foundation: Math.round(data?.foundation_course?.completed ?? 0),
      element: Math.round(data?.element.start_elements ?? 0),
      elements: data?.element.elements ?? [],
      student_surveys_percentage: Math.round(data?.survey?.average_completed_survey ?? 0),
      student_surveys: Math.round(data?.survey?.number_survey_instances ?? 0),
      student_responses: Math.round(data?.survey?.number_survey_responses ?? 0),
      teacher_courses_percentage: Math.round(data?.teacher_courses.average_completed ?? 0),
      teacher_courses_started: Math.round(data?.teacher_courses.start ?? 0),
      teacher_courses_finished: Math.round(data?.teacher_courses.complete ?? 0),
      lead_courses_percentage: Math.round(data?.lead_programme_courses.average_completed ?? 0),
      lead_courses_started: Math.round(data?.lead_programme_courses.start ?? 0),
      lead_courses_finished: Math.round(data?.lead_programme_courses.complete ?? 0),
      cost: data?.time_cost?.cost ?? 0,
      hours: Math.ceil(data?.time_cost?.hours ?? 0),
      cost_per_hour: (data?.time_cost?.cost / 100 / data?.time_cost?.hours).toFixed(2).replace(/[.,]00$/, '') ?? 0,
    };
  };

  const dataSource = dataRetriever(schoolMetrics?.data)

  const academicYearsList = (years: any) => {
    const yearsList = years.map((year: any, index: any) => {
      return ` ${year.label}`;
    });
    if (yearsList.length === academicYearsOptions.length) return 'all';
    return yearsList.toString();
  };

  const pdfTemplate = [
    {
      header: { noTimestamp: true },
      title: {
        text: isFree ? me?.organisation : dataSource.school,
        customMarginBottom: '4px',
        customFontSize: '18px',
        customMarginLeft: '25px',
        customFontFamily: 'Futura PT Demi, sans-serif',
      },
      subtitle: isFree
        ? undefined
        : {
            text:
              academicYears.length === 0 || academicYearsList(academicYears) === 'all'
                ? 'Information for all academic years'
                : `Information for academic year${academicYears.length > 1 ? 's' : ''}${
                    academicYearsList(academicYears) ? academicYearsList(academicYears) : ''
                  }`,
            customMarginBottom: '2px',
            customFontSize: '16px',
            customMarginLeft: '25px',
          },
      secondSubtitle: {
        text: isFree
          ? `30 teachers at ${me?.organisation} are using the Great Teaching Toolkit`
          : `${schoolMetrics.data?.teachers} teacher${schoolMetrics.data?.teachers === 1 ? '' : 's'} at ${
              schoolMetrics.data?.school
            } ${schoolMetrics.data?.teachers === 1 ? 'is' : 'are'} using the Great Teaching Toolkit`,
        customMarginBottom: '8px',
        customFontSize: '16px',
        customMarginLeft: '25px',
      },
      views: [
        {
          customMarginBottom: '16px',
          customMarginTop: '16px',
          customHeight: '250px',
          content: [
            {
              customFontFamily: 'Futura PT Demi, sans-serif',
              text: 'Getting started',
              customMarginBottom: '12px',
            },
            {
              text: `${dataSource.introduction}%`,
              customMarginBottom: '4px',
              customFontSize: '28px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
            {
              text: 'of your teachers have completed Section 1 of the Foundation Course',
              customMarginBottom: '12px',
              customFontSize: '17px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
            {
              text: `${dataSource.foundation}%`,
              customMarginBottom: '4px',
              customFontSize: '28px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
            {
              text: 'of your teachers have completed the Foundation Course',
              customFontSize: '17px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
          ],
        },
        {
          customHeight: '250px',
          content: [
            {
              text: 'Elements of Great Teaching',
              customMarginBottom: '12px',
              customFontFamily: 'Futura PT Demi, sans-serif',
            },
            {
              text: `${dataSource.element}%`,
              customMarginBottom: '8px',
              customFontSize: '35px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
            {
              text: 'of your teachers chose an element of Great Teaching to improve',
              customMarginBottom: '10px',
              customFontSize: '20px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
            {
              text:
                dataSource.elements.length > 0 &&
                dataSource.elements.map((element: any, index: any) => {
                  return `${element}${index + 1 === dataSource.elements.length ? '' : ', '}`;
                }),
              customMarginBottom: '4px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
          ],
        },
      ],
      footer: true,
    },
    {
      views: [
        {
          customMarginTop: '15px',
          customHeight: '180px',
          customMarginBottom: '12px',
          content: [
            {
              customFontFamily: 'Futura PT Demi, sans-serif',
              text: 'Student surveys',
              customMarginBottom: '5px',
            },
            {
              text: `${dataSource.student_surveys_percentage}%`,
              customMarginBottom: '5px',
              customFontSize: '35px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
            {
              text: 'of your teachers generated feedback using student surveys',
              customMarginBottom: '8px',
              customFontSize: '21px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
            {
              text: `${dataSource.student_surveys} student surveys`,
              customWidth: '100%',
              customTextAlign: 'left',
            },
            {
              text: `${dataSource.student_responses} student responses`,
              customWidth: '100%',
              customTextAlign: 'left',
            },
          ],
        },
        {
          customMarginBottom: '12px',
          customHeight: '180px',
          content: [
            {
              customFontFamily: 'Futura PT Demi, sans-serif',
              text: 'Teacher courses',
              customMarginBottom: '5px',
            },
            {
              text: `${dataSource.teacher_courses_percentage}%`,
              customMarginBottom: '1px',
              customFontSize: '35px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
            {
              text: 'course completion rate',
              customWidth: '100%',
              customTextAlign: 'center',
              customMarginBottom: '5px',
              customFontSize: '21px',
            },
            {
              text: `${dataSource.teacher_courses_started} courses started`,
              customWidth: '100%',
              customTextAlign: 'left',
            },
            {
              text: `${dataSource.teacher_courses_finished} courses finished`,
              customWidth: '100%',
              customTextAlign: 'left',
            },
          ],
        },
        {
          customMarginBottom: '12px',
          customHeight: '180px',
          content: [
            {
              customFontFamily: 'Futura PT Demi, sans-serif',
              text: 'Lead Programmes',
              customMarginBottom: '5px',
            },
            {
              text: `${dataSource.lead_courses_percentage}%`,
              customMarginBottom: '2px',
              customFontSize: '35px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
            {
              text: 'course completion rate',
              customMarginBottom: '3px',
              customFontSize: '21px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
            {
              text: `${dataSource.lead_courses_started} courses started`,
              customWidth: '100%',
              customTextAlign: 'left',
            },
            {
              text: `${dataSource.lead_courses_finished} courses finished`,
              customWidth: '100%',
              customTextAlign: 'left',
            },
          ],
        },
        {
          customHeight: '140px',
          content: [
            {
              customFontFamily: 'Futura PT Demi, sans-serif',
              text: 'Time on CPD activities',
              customMarginBottom: '12px',
            },
            {
              text: `${dataSource.hours} hour${dataSource.hours > 1 ? 's' : ''}`,
              customMarginBottom: '12px',
              customFontSize: '35px',
              customWidth: '100%',
              customTextAlign: 'center',
            },
            parseInt(dataSource.cost_per_hour) < 100 && parseInt(dataSource.cost_per_hour) > 0
              ? {
                  text: `£${dataSource.cost_per_hour} per hour`,
                  customMarginBottom: '4px',
                  customFontSize: '16px',
                  customWidth: '100%',
                  customTextAlign: 'center',
                }
              : { undefined },
          ],
        },
      ],
      footer: true,
    },
  ];

  const handleDownload = async () => {
    setLoading(true);
    try {
      const blob = await pdf(
        <Document>
          {pdfTemplate.map((pdfPage: any) => {
            return (
              <Page size="A4" style={PDF_STYLES.page} orientation={'portrait'} key={Math.random()}>
                {pdfPage.header && <PDFLayout.Header noTimestamp={pdfPage.header.noTimestamp ?? false} />}
                {pdfPage.title && (
                  <PdfTextMetrics
                    text={pdfPage.title.text ?? null}
                    customMarginBottom={pdfPage.title.customMarginBottom ?? null}
                    customFontSize={pdfPage.title.customFontSize ?? null}
                    customMarginLeft={pdfPage.title.customMarginLeft ?? null}
                    customFontFamily={pdfPage.title.customFontFamily ?? null}
                  />
                )}
                {pdfPage.subtitle && (
                  <PdfTextMetrics
                    text={pdfPage.subtitle.text ?? null}
                    customMarginBottom={pdfPage.subtitle.customMarginBottom ?? null}
                    customFontSize={pdfPage.subtitle.customFontSize ?? null}
                    customMarginLeft={pdfPage.subtitle.customMarginLeft ?? null}
                    customFontFamily={pdfPage.subtitle.customFontFamily ?? null}
                  />
                )}
                {pdfPage.secondSubtitle && (
                  <PdfTextMetrics
                    text={pdfPage.secondSubtitle.text ?? null}
                    customMarginBottom={pdfPage.secondSubtitle.customMarginBottom ?? null}
                    customFontSize={pdfPage.secondSubtitle.customFontSize ?? null}
                    customMarginLeft={pdfPage.secondSubtitle.customMarginLeft ?? null}
                    customFontFamily={pdfPage.secondSubtitle.customFontFamily ?? null}
                  />
                )}
                {pdfPage.views.length > 0 &&
                  pdfPage.views.map((metric: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <PdfViewMetrics
                          customMarginBottom={metric.customMarginBottom ?? null}
                          customMarginTop={metric.customMarginTop ?? null}
                          customHeight={metric.customHeight ?? null}
                          content={
                            metric.content.length > 0 &&
                            metric.content.map((con: any, index: any) => {
                              return (
                                <React.Fragment key={index}>
                                  <PdfTextMetrics
                                    text={con.text ?? null}
                                    customMarginBottom={con.customMarginBottom ?? null}
                                    customFontSize={con.customFontSize ?? null}
                                    customWidth={con.customWidth ?? null}
                                    customTextAlign={con.customTextAlign ?? null}
                                    customMarginLeft={con.customMarginLeft ?? null}
                                    customFontFamily={con.customFontFamily ?? null}
                                  />
                                </React.Fragment>
                              );
                            })
                          }
                        />
                      </React.Fragment>
                    );
                  })}
                {pdfPage.footer && (
                  <View
                    style={{
                      position: 'absolute',
                      fontSize: 12,
                      bottom: 30,
                      left: 0,
                      right: 0,
                      textAlign: 'center',
                    }}
                  >
                    <Text style={PDF_STYLES.copy}>© Evidence Based Education {new Date().getFullYear()}</Text>
                    <Text style={PDF_STYLES.copy}>
                      Printed on: {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
                    </Text>
                  </View>
                )}
              </Page>
            );
          })}
        </Document>
      ).toBlob();
      saveAs(blob, 'development_activities_report');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={'flex mb-4'}>
        {!isFree && (
          <div className={'w-1/3 mb-8'}>
            <Selector
              id={'selector_academic_years'}
              placeholder={'Select Academic year'}
              isSearchable
              isMulti
              options={academicYearsOptions}
              value={academicYears}
              onChange={(options: any) => {
                setAcademicYears(options == null ? [] : options);
              }}
            />
          </div>
        )}
        <div className={'ml-8 items-center border-light border-box'}>
          <p className={'text-xl'}>
            {schoolMetrics.data?.teachers} teacher{isFree ? 's' : schoolMetrics.data?.teachers === 1 ? '' : 's'} at{' '}
            {isFree ? `${me?.organisation} are` : schoolMetrics.data?.school}{' '}
            {!isFree && schoolMetrics.data?.teachers === 1 ? 'is' : 'are'} using the Great Teaching Toolkit
          </p>
        </div>
        <Button className={'ml-auto'} onClick={() => handleDownload()}>
          {loading ? 'Loading...' : 'Download'}
        </Button>
      </div>

      <div className="grid {/*grid-cols-1 xl:grid-cols-2 xl:grid-rows-3*/} gap-4">
        <MetricBox
          title={'Getting started'}
          content={
            <div className={'h-full w-full flex gap-4'}>
              <div className={'w-1/2'}>
                <div className="w-full h-full">
                  <Progress progress={dataSource.introduction} label="Introduction" />
                  <div className="w-3/4 mx-auto">
                    <p className={'font-bold text-gray-500'} id={'metrics_introduction'}>
                      Have completed Section 1 of the Foundation Course
                    </p>
                  </div>
                </div>
              </div>
              <div className={'w-1/2'}>
                <div className="w-full h-full">
                  <Progress progress={dataSource.foundation} label="Foundation" />
                  <div className="w-3/4 mx-auto">
                    <p className={'font-bold mx-auto text-gray-500'} id={'metrics_foundation'}>
                      Have completed the Foundation Course
                    </p>
                  </div>
                </div>
              </div>
            </div>
          }
        />
        <MetricBox
          title={'Elements of Great Teaching'}
          content={
            <div className={'h-full w-full gap-4 2xl:grid 2xl:grid-cols-3'}>
              <div className={'col-span-2'}>
                <div className="mx-auto">
                  <p className={'font-bold text-9xl mt-8 w-3/4 ml-8'}>{dataSource.element}%</p>
                  <p className={'text-gray-500 font-bold w-3/4 ml-8 mt-2'}>
                    Of your teachers chose an element of Great Teaching to improve
                  </p>
                  <div className={'ml-4 mt-2 flex flex-row gap-1 flex-wrap w-full'}>
                    {dataSource.elements.map((value: any, index: number) => (
                      <div className={'mt-4 ml-2'} key={index}>
                        <ElementSquare value={value} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          }
        />
        <MetricBox
          title={'Student surveys'}
          content={
            <div className={'h-full w-full gap-4 grid grid-cols-3'}>
              <div className={'col-span-2'}>
                <div className="mx-auto">
                  <p className={'font-bold text-9xl mt-8 w-3/4 ml-8'}>
                    {dataSource.student_surveys_percentage}%
                  </p>
                  <p className={'text-gray-500 font-bold w-3/4 ml-8 mt-2'}>
                    Of your teachers generated feedback using student surveys
                  </p>
                </div>
              </div>
              <div className={'mt-12 pr-2'}>
                <div className="inline-flex items-center mb-8">
                  <span className="inline-block mr-4 font-bold text-2xl">
                    {dataSource.student_surveys}
                  </span>
                  <span className="text-gray-500 font-bold text-right">STUDENT SURVEYS</span>
                </div>
                <div className="inline-flex items-center">
                  <span className="inline-block mr-4 font-bold text-2xl">
                    {dataSource.student_responses}
                  </span>
                  <span className="text-gray-500 font-bold text-right">STUDENT RESPONSES</span>
                </div>
              </div>
            </div>
          }
        />
        <MetricBox
          title={'Teacher courses'}
          content={
            <div className={'h-full w-full gap-4 grid grid-cols-3'}>
              <div className={'ml-8 mt-12 pr-4'}>
                <div className="inline-flex items-center mb-8">
                  <span className="inline-block mr-4 font-bold text-2xl">
                    {dataSource.teacher_courses_started}
                  </span>
                  <span className="text-gray-500 font-bold text-right">COURSES STARTED</span>
                </div>
                <div className="inline-flex items-center">
                  <span className="inline-block mr-4 font-bold text-2xl">
                    {dataSource.teacher_courses_finished}
                  </span>
                  <span className="text-gray-500 font-bold text-right">COURSES FINISHED</span>
                </div>
              </div>
              <div className={'col-span-2'}>
                <div className="mx-auto">
                  <p className={'font-bold text-9xl mt-8 w-3/4 ml-8'}>
                    {dataSource.teacher_courses_percentage}%
                  </p>
                  <p className={'text-gray-500 font-bold w-3/4 ml-8 mt-2'}>Course completion rate</p>
                </div>
              </div>
            </div>
          }
        />
        <MetricBox
          title={'Lead Programmes'}
          content={
            <div className={'h-full w-full gap-4 grid grid-cols-3'}>
              <div className={'ml-8 mt-12 pr-12'}>
                <div className="inline-flex items-center mb-8">
                  <span className="inline-block mr-4 font-bold text-2xl">
                    {dataSource.lead_courses_started}
                  </span>
                  <span className="text-gray-500 font-bold text-right">COURSES STARTED</span>
                </div>
                <div className="inline-flex items-center">
                  <span className="inline-block mr-4 font-bold text-2xl">
                    {dataSource.lead_courses_finished}
                  </span>
                  <span className="text-gray-500 font-bold text-right">COURSES FINISHED</span>
                </div>
              </div>
              <div className={'col-span-2'}>
                <div className="mx-auto">
                  <p className={'font-bold text-9xl mt-8 w-3/4 ml-8'}>
                    {dataSource.lead_courses_percentage}%
                  </p>
                  <p className={'text-gray-500 font-bold w-3/4 ml-8 mt-2'}>Course completion rate</p>
                </div>
              </div>
            </div>
          }
        />
        <MetricBox
          title={'Time on CPD activities'}
          content={
            <div className={'h-full w-full gap-4 2xl:grid 2xl:grid-cols-3 mt-8'}>
              <div className={'col-span-2'}>
                <div className="mx-auto">
                  <div className="flex mb-8">
                    <p className={'font-bold text-9xl mt-8 mx-8'}>{dataSource.hours}</p>
                    <span className="text-gray-500 font-bold mt-auto mb-2">HOUR{dataSource.hours > 1 ? 'S' : ''}</span>
                  </div>
                  {parseInt(dataSource.cost_per_hour) < 100 && parseInt(dataSource.cost_per_hour) > 0 ? (
                    <p className={'text-gray-500 font-bold w-3/4 ml-8 mt-2'}>
                      &#163;{dataSource.cost_per_hour} per hour
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};
export default AggregatedMetrics;
